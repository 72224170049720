import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";
import * as tf from "@tensorflow/tfjs";

function FaceMetrics({ bodyMetrics, bodyHeight }) {
  const cameraRef = useRef();
  const canvasRef = useRef();
  const [bodyComplete, setbodyComplete] = useState(0);
  const [tbbpct, setTbbpct] = useState(50);
  const [tbtpct, setTbtpct] = useState(50);
  const [csize, setCsize] = useState(0);
  const samplesRef = useRef(0);
  const modelRef = useRef();
  const tbModelRef = useRef();
  const csModelRef = useRef();

  useEffect(() => {
    if (!modelRef.current) {
      tf.loadLayersModel("/models/rel3/model.json").then((m) => {
        modelRef.current = m;
        modelRef.current.summary();
      });
    }
    if (!tbModelRef.current) {
      tf.loadLayersModel("/models/tbmodel1/model.json").then((m) => {
        tbModelRef.current = m;
        tbModelRef.current.summary();
      });
    }
    if (!csModelRef.current) {
      tf.loadLayersModel("/models/csmodel1/model.json").then((m) => {
        csModelRef.current = m;
        csModelRef.current.summary();
      });
    }
  }, []);

  function drawImge(drawFunc) {
    const video = cameraRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      var ctx = canvas.getContext("2d");
      // We want also the canvas to display de image mirrored

      // ctx.drawImage(video.video, 0, 0, canvas.width, canvas.height);
      drawFunc(
        ctx,
        video.video,
        video.video.videoWidth,
        video.video.videoHeight,
        (nw = video.video.videoWidth, nh = video.video.videoHeight) => {
          canvas.width = nw;
          canvas.height = nh;
        }
      );
    }
  }
  function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {
    if (arguments.length === 2) {
      x = y = 0;
      w = ctx.canvas.width;
      h = ctx.canvas.height;
    }

    // default offset is center
    offsetX = typeof offsetX === "number" ? offsetX : 0.5;
    offsetY = typeof offsetY === "number" ? offsetY : 0.5;

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.videoWidth,
      ih = img.videoHeight,
      r = Math.min(w / iw, h / ih),
      nw = iw * r, // new prop. width
      nh = ih * r, // new prop. height
      cx,
      cy,
      cw,
      ch,
      ar = 1;

    // decide which gap to fill
    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh; // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
  }

  var bodyCompleteStack = [];
  var csizeStack = [];
  var sexTStack = [];
  var sexBStack = [];
  const metrix = async () => {
    drawImge((ctx, video, w, h, adjuster) => {
      faceapi
        .detectSingleFace(video, new faceapi.TinyFaceDetectorOptions())
        .then((face) => {
          adjuster(300, 400);
          if (!face) {
            return;
          }

          const regionsToExtract = [
            new faceapi.Rect(
              face.box.x,
              face.box.y,
              face.box.width,
              face.box.height
            ),
          ];
          if (!modelRef.current) {
            return;
          }
          if (samplesRef.current <= 10) {
            samplesRef.current += 1;
          } else {
            cameraRef.current = null;
            return;
          }
          faceapi.extractFaces(video, regionsToExtract).then((snip) => {
            // imgRef.current.src = snip[0].toDataURL();
            faceapi.computeFaceDescriptor(snip[0]).then((desc) => {
              let input = [bodyHeight / 200, ...Array.from(desc)];
              input = tf.tensor([input]);
              let output = modelRef.current.predict(input);
              let sexoutput = tbModelRef.current
                .predict(tf.tensor([desc]))
                .dataSync();
              let csoutput = csModelRef.current
                .predict(tf.tensor([desc]))
                .dataSync();
              sexTStack.push(sexoutput[1]);
              sexBStack.push(sexoutput[0]);
              let sexT = 0;
              let sexB = 0;
              sexTStack.forEach((x) => (sexT += x));
              sexBStack.forEach((x) => (sexB += x));
              setTbbpct(Math.round((sexT / sexTStack.length) * 100));
              setTbtpct(Math.round((sexB / sexBStack.length) * 100));
              csizeStack.push(csoutput[0] * 14 + 9);
              let avcsize = 0;
              csizeStack.forEach((s) => (avcsize += s));
              setCsize(Math.round((10 * avcsize) / csizeStack.length) / 10);

              bodyCompleteStack.push(output.dataSync()[0] * 200);
              let avw = 0;
              bodyCompleteStack.forEach((element) => {
                avw += element;
              });
              avw /= bodyCompleteStack.length;
              setbodyComplete(Math.round(avw));
            });
          });
          drawImageProp(
            ctx,
            video,
            0,
            0,
            300,
            400,
            face.relativeBox.x + face.relativeBox.width / 2,
            face.relativeBox.y + face.relativeBox.height / 2
          );
        });
      setTimeout(metrix, 200);
    });
    // faceapi
    //   .computeFaceDescriptor(face)
    //   .then((r) => {
    //     setbodyComplete({ ...bodyMetrics, face_features: Array.from(r) });
    //   })
    //   .catch((e) => console.log(e));
  };

  const videoConstraints = {
    width: 1920,
    height: 1080,
    facingMode: "user",
  };

  return (
    <div class="" style={{ position: "relative", width: "100%" }}>
      {samplesRef.current < 10 ? (
        <div>
          <p>Collecting samples, look at the camera</p>
          <progress value={samplesRef.current} max="10" />
        </div>
      ) : (
        <>
          <div class="text-wrap d-block lead">
            FOR: <b>{bodyHeight}cm</b> PREDICT: <b>{bodyComplete}kg</b>
          </div>
          <div class="text-wrap d-block ">
            t-param: <b>{tbtpct}%</b> b-param: <b>{tbbpct}%</b>
          </div>
          <div class="text-wrap d-block ">
            c-param: <b>{csize} </b>
          </div>
        </>
      )}
      <Webcam
        imageSmoothing="false"
        ref={cameraRef}
        onLoadedData={metrix}
        videoConstraints={videoConstraints}
        style={{
          zIndex: "-1",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          visibility: "hidden",
          position: "absolute",
        }}
      ></Webcam>
      <canvas
        ref={canvasRef}
        style={{
          zIndex: "1",
          width: "300px",
          height: "400px",
          position: "relative",
        }}
      />
    </div>
  );
}

export default FaceMetrics;
