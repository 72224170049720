import FaceMetrics from "./components/FaceMetrics";
import * as faceapi from "face-api.js";
import { useEffect, useState, useRef } from "react";
import Consent from "./components/Consent";
import ConsentCheck from "./components/ConsentCheck";

function App() {
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [goClicked, setGoClicked] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(185);
  const bodyHeightRef = useRef(bodyHeight);
  const loadModels = () => {
    return new Promise(async (res, err) => {
      try {
        await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
        await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
        await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
        await faceapi.nets.ageGenderNet.loadFromUri("/models");
      } catch (err) {
        return err(err);
      }
      return res();
    });
  };
  useEffect(() => {
    console.log("loading models");
    loadModels().then(() => setModelsLoaded(true));
  }, []);
  return (
    <div class="container">
      {modelsLoaded && goClicked ? (
        <FaceMetrics bodyHeight={bodyHeight}></FaceMetrics>
      ) : (
        <>
          <input
            type="range"
            min={150}
            max={220}
            onChange={(e) => {
              bodyHeightRef.current = e.target.value;
              setBodyHeight(bodyHeightRef.current);
            }}
            value={bodyHeight}
          />
          <p>{bodyHeight}cm</p>
          <button
            class=" mt-5 btn btn-info"
            disabled={!modelsLoaded}
            onClick={() => setGoClicked(true)}
          >
            <span
              class={!modelsLoaded ? "spinner-border spinner-border-sm" : ""}
              role="status"
            ></span>
            <span class="p-3">
              {modelsLoaded
                ? "Models loaded, click to contiunue"
                : "Loading models..."}
            </span>
          </button>
          <div class="cotnent m-5">
            <img src="/nun.gif" style={{ width: "10rem" }} />
          </div>
        </>
      )}
    </div>
  );
}

export default App;
