import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./bootstrap.min.css";
import "./style.css";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import Consent from "./components/Consent";

Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <>
      <nav class="navbar navbar-dark bg-dark">
        <div class="navbar-brand p-2">AI experiments &#x1F60F;</div>
      </nav>
      <main class="p-5">
        <App />
      </main>
    </>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
